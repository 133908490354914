import { api, createCrudService } from '@cargill/shared'

const baseUrl = '/api/controltower/parameters'

const service = createCrudService(baseUrl, api, {
  importLogUrl: '/api/controltower/importLog'
})
service.getAcceptableVolume = async () => {
  return (await api.get(`${baseUrl}/getAcceptableVolume?`))?.data
}

export default service
