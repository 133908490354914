<template>
  <div v-if="metadata != null && showCrud">
    <custom-cg-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :details="details"
      :reloadData="reloadData"
      @onReloadData="reloadData = false"
      translatePrefix="controlTower.pages"
    />
    <bc-modal
      v-if="showModal"
      v-model="showModal"
      :title="
        $t(
          'controlTower.pages.takeUpLotReportInputMaster.messages.infoAcceptableVolume'
        )
      "
      width="800"
      height="max-content"
    >
      <acceptable-Volume
        :parameterData="parameters"
        :service="serviceParameter"
        @hideModal="hideModal"
      >
      </acceptable-Volume>
    </bc-modal>
    <comment-dialog
      :show="showComments"
      :currentRow="commentRow"
      :comments="comments"
      :service="serviceComment"
      :fieldParentId="'takeUpLotReportInput' + 'Id'"
      :page="title"
      :fields="fields"
      @input="showComments = $event"
    />
  </div>
</template>

<script>
import {
  CustomCgCrudMetaView,
  helpers,
  useTransactions,
  CommentDialog
} from '@cargill/shared'

import service from '../api/takeUpLotReportInputMasterService'
import takeUpLotReportInputService from '../api/takeUpLotReportInputService'
import serviceComment from '../api/takeUpLotReportInputCommentService'
import serviceParameter from '../api/parametersService'
import { CrudAction } from '@brain/core'
import acceptableVolume from '../components/take-up-lot-report-Input/AcceptableVolume'

export default {
  components: { CustomCgCrudMetaView, CommentDialog, acceptableVolume },
  data() {
    return {
      reloadData: false,
      metadata: null,
      crudService: service,
      details: {},
      showCrud: true,
      serviceComment: serviceComment,
      serviceParameter: serviceParameter,
      showComments: false,
      showModal: false,
      commentRow: {},
      comments: [],
      parameters: [],
      title: 'takeUpLotReportInputMaster.takeUpLotReportInput',
      fields: [
        'company',
        'contract',
        'cropYear',
        'companyClass',
        'deliveryProducerName',
        'ginName'
      ]
    }
  },
  methods: {
    translate(key) {
      return this.$t(`controlTower.pages.${this.title}.${key}`)
    },
    async getTakeUpLotReportInputMeta() {
      const takeUpLotReportInputMeta =
        await takeUpLotReportInputService.getMetaWithValidation()

      helpers.processMetaDetails(
        takeUpLotReportInputMeta,
        'takeUpLotReportInput',
        'bookId',
        'bookId',
        takeUpLotReportInputService
      )

      takeUpLotReportInputMeta.actions = [
        {
          title: () => this.$t('controlTower.pages.stuffingPortal.comments'),
          className: () => 'fas fa-comment-alt-lines',
          enable: true,
          callback: async ({ node }) => {
            this.commentRow = node.data
            await this.getComments()
            this.showComments = true
          }
        },
        {
          title: this.$t(
            'controlTower.pages.takeUpLotReportInputMaster.messages.calculateUsBucket'
          ),
          className: 'fas fa-calculator',
          enable: true,
          callback: ({ node }) => {
            takeUpLotReportInputService
              .calculateUsBucket(node.data.id)
              .then(() => {
                this.reload()
              })
          }
        }
      ]
      return takeUpLotReportInputMeta
    },
    canDeleteReportInputTakeUp() {
      const { canDelete } = useTransactions()
      return canDelete('takeUpLotReportInput')
    },
    async getMetaDetails() {
      return await Promise.all([this.getTakeUpLotReportInputMeta()])
    },    
    async getComments() {
      const filter = `sort=date:desc&filterQuery=takeUpLotReportInputId%3Din%3A${this.commentRow.id}`
      this.comments = await this.serviceComment.getAll(filter)
    },
    async settingsAcceotableVolume() {
      this.parameters = await this.getAcceptableVolume()
      this.showModal = true
    },
    hideModal(){
      this.showModal = false
    },
    async getAcceptableVolume() {
      return await this.serviceParameter.getAcceptableVolume()
    },
    reloadGrid() {
      this.showCrud = false
      setTimeout(() => {
        this.showCrud = true
      }, 10)
    },
    async confirmDeleteAllInputTakeUpRejectedLots() {
      return helpers.createConfirmPromise(this, {
        title: this.$t('core.crud.attention'),
        subtitle: this.$t(
          'controlTower.pages.takeUpLotReportInputMaster.messages.confirmationToDeleteRejectedLots'
        )
      })
    },
    async confirmDeleteAllInputTakeUpByBook() {
      return helpers.createConfirmPromise(this, {
        title: this.$t('core.crud.attention'),
        subtitle: this.$t(
          'controlTower.pages.takeUpLotReportInputMaster.messages.confirmationToDeleteAllInputTakeUp'
        )
      })
    },
    async confirmDeleteAllInputTakeUpCompleted(bookId) {
      let [confirm, hasLotRejected] = await Promise.all([
        this.confirmDeleteAllInputTakeUpByBook(),
        takeUpLotReportInputService.hasLotWithRejectedTakeUpPremiumDiscountByBook(
          bookId
        )
      ])
      if (confirm) {
        if (hasLotRejected) {
          await new Promise((x) => setTimeout(x))
          confirm = await this.confirmDeleteAllInputTakeUpRejectedLots()
        }
      }
      return confirm
    },
    async deleteAllInputTakeUpHandler(bookId) {
      let confirm = await this.confirmDeleteAllInputTakeUpCompleted(bookId)
      if (confirm) {
        try {
          await takeUpLotReportInputService.deleteByBook(bookId)
          this.notify.success({
            title: this.$t('application.misc.deleteSuccess')
          })
          this.reload()
        } catch (error) {
          console.log(error)
        }
      }
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMetaWithValidation(),
        this.getMetaDetails()
      ])
      meta.details = metaDetails
      if (this.canDeleteReportInputTakeUp()) {
        meta.actions = [
          {
            title: this.$t(
              'controlTower.pages.takeUpLotReportInputMaster.messages.calculateUsBucket'
            ),
            className: 'fas fa-calculator',
            enable: true,
            callback: ({ node }) => {
              takeUpLotReportInputService
                .calculateUsBucketByBook(node.data.id)
                .then(() => {
                  this.reloadData = true
                })
            }
          },
          {
            title: this.$t(
              'controlTower.pages.takeUpLotReportInputMaster.messages.deleteAllInputTakeUp'
            ),
            className: 'far fa-trash',
            callback: ({ node }) => {
              this.deleteAllInputTakeUpHandler(node.data.id)
            }
          }
        ]
      }
      meta.customToolbarActions = [
        {
          canShow: true,
          titleKey: this.$t(
            'controlTower.pages.takeUpLotReportInputMaster.messages.settingsAcceptableVolume'
          ),
          onClick: this.settingsAcceotableVolume,
          iconColor: '#959DB5',
          faIcon: 'fas fa-cogs'
        }
      ]
      return meta
    },
    reload() {
      this.reloadData = true
    },
    registerCrudProcessing() {
      takeUpLotReportInputService.registerPostProcessing(
        CrudAction.DELETE,
        this.reload
      )
    },
    cancelCrudProcessing() {
      takeUpLotReportInputService.cancelPostProcessing(
        CrudAction.DELETE,
        this.reload
      )
    }
  },
  beforeDestroy() {
    this.cancelCrudProcessing()
  },
  created() {
    this.registerCrudProcessing()
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta, 'controlTower.pages')
      this.metadata = meta
    })
  }
}
</script>
