<template>
  <div>
    <div>
      <div class="body-content">
        <div class="filter">
          <div class="filter-container">
            <bc-numeric-field
              v-if="parameterMin"
              :label="getLabelMin()"
              :placeholder="getLabelMin()"
              :filled="true"
              v-model="parameterMin.value"
            ></bc-numeric-field>

            <bc-numeric-field
              v-if="parameterMax"
              :label="getLabelMax()"
              :placeholder="getLabelMax()"
              :filled="true"
              v-model="parameterMax.value"
            ></bc-numeric-field>
          </div>
        </div>
      </div>
      <bc-card-actions class="modal-footer">
        <bc-spacer></bc-spacer>

        <bc-btn
          class="v-btn v-btn--has-bg theme--dark elevation-0 v-size--default primary"
          text
          @click="save()"
        >
          {{ $t('core.misc.save') }}
        </bc-btn>
      </bc-card-actions>
    </div>
  </div>
</template>

<script>
import AcceptableVolumeList from '../../constants/AcceptableVolumeList'

export default {
  name: 'AcceptableVolume',
  components: {},
  data: () => ({
    minValue: null,
    maxValue: null,
    parameterMin: null,
    parameterMax: null
  }),
  props: {
    parameterData: {
      type: [],
      default: null
    },
    service: {
      type: Object,
      default: null
    }
  },
  async created() {
    this.data = this.parameterData

    this.parameterMin = await this.getParameterMin()
    this.parameterMax = await this.getParameterMax()
  },
  methods: {
    getLabelMax() {
      return this.$t(
        `controlTower.pages.takeUpLotReportInputMaster.acceptableVolume.maxLabel`
      )
    },
    getParameterMax() {
      return this.data.find(
        (x) => x.code === AcceptableVolumeList.AcceptableVolumeMax
      )
    },
    getParameterMin() {
      return this.data.find(
        (x) => x.code === AcceptableVolumeList.AcceptableVolumeMin
      )
    },
    getLabelMin() {
      return this.$t(
        `controlTower.pages.takeUpLotReportInputMaster.acceptableVolume.minLabel`
      )
    },
    getData() {
      return this.data
    },
    processUpdateBatch() {
      let rowIdCounter = 1
      let allDto = this.data.map((rowNode) => {
        const rowId = rowIdCounter++

        return {
          dto: {
            ...rowNode,
            rowId: rowId
          }
        }
      })
      return allDto
    },    
    async save() {
      try {
        const data = await this.processUpdateBatch()

        let response = await this.service.batchUpdate(data)

        if (response?.errorItems) {
          response.errorItems.forEach((errorItem) => {
            errorItem.fieldErrors.forEach((errorField) => {
              this.$refs.formContainer.form.errors[errorField.fieldName] =
                this.$t(errorField.keyMessage)
            })
          })
          this.notify.error({
            title: this.$t('error.saveUpdateError')
          })
        } else {
          this.notify.success({
            title: this.$t('core.crud.updateSuccess')
          })
          this.$emit('hideModal')
        }
      } catch (ex) {
        console.error('Failed to submit changes', ex)
        this.notify.error({
          title: this.$t('error.unexpectedError')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.general-data-container {
  display: flex;
  align-items: center;
  padding: 0 !important;
  color: #959db8;
  margin-bottom: 10px;
  height: 3rem;
}

.name-content {
  span {
    color: #929ab1;
    margin-left: 20px;
    font: bold 14px Aller !important;
    text-transform: uppercase;
  }
}

.filter {
  margin-left: 1rem;
  padding: 10px 0;
}

.filter-container {
  display: flex;
  margin-top: 16px;

  & > :not(:first-child) {
    margin-left: 16px;
  }
}

.body-content {
  display: flex;
  justify-content: center;
  text-align: left;
  flex-direction: column;
  text-transform: uppercase;
  background-color: #232834;
  border-radius: 4px;
}

.LabelData {
  font-size: 14px;
  margin-left: 4px;
}
.v-dialog > .v-card > .v-card__text {
  padding: 0;
}
.v-dialog > .v-card > .v-card__title {
  padding: 16px 0px 16px;
}
.container-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  background-color: #262e39;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 4px;
}
.v-dialog .v-card .modal-footer {
  padding: 8px 0 8px 0;
}
.modal-header {
  background-color: #262e39;
}
.modal-header .v-card__title {
  color: rgb(149, 157, 181);
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  padding: 12px 20px 12px 20px;
}

.modal.v-card.brain {
  padding: 20px 20px 12px 20px;
}
</style>
